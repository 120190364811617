import Client from './pages/client/Client';

function App() {
  return (
    <div className="App">
        <Client />
    </div>
  );
}

export default App;
